import axios from "axios";

const API_SERVER = "http://lscmlandslp.eastasia.cloudapp.azure.com/landslp2/";

function isSuccess(response){
  try{
    if (response.data.meta.code == "200"){
      return true;
    }
  }
  catch(error){

  }
  return false;
}

async function post(endpoint, params) {
  // console.log(API_SERVER + endpoint);
  try {
    const response = await axios.post(API_SERVER + endpoint, params);
    return response;
  } catch (error) {
    console.log(error);
  }
}

async function get(endpoint) {
  try {
    const response = await axios.get(API_SERVER + endpoint);
    return response;
  } catch (error) {
    console.log(error);
  }
}

export {
  post,
  get,
  isSuccess,
};
