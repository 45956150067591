import logo from "./logo.svg";
import { FileOutlined, PieChartOutlined, UserOutlined } from "@ant-design/icons";
import "./App.css";
import "./index.css";
import { PlusOutlined } from "@ant-design/icons";
import MenuItem from "antd/es/menu/MenuItem";
import { Button, Table, Typography, DatePicker, Form, Input, Menu, Layout, Popconfirm, message, theme, Checkbox } from "antd";
import { useEffect, useRef, useState } from 'react';
import * as API from "./APIService";
import classNames from 'classnames';
import ResizeObserver from 'rc-resize-observer';
import { VariableSizeGrid as Grid } from 'react-window';

const { Header, Footer, Sider, Content } = Layout;
const { RangePicker } = DatePicker;
const { TextArea } = Input;
const { Title } = Typography;

const BEACONBODY = {
  BM_Id: 311,
  BM_CreateTime: "2021-10-26T00:00:00",
  BM_UpdateTime: "2021-10-26T00:00:00",
  BM_AnchorId: null,
  BM_BeaconId: "IB0000000000XX",
  BM_Uuid: "b19af0047f2a49728f3937d26c29cb9e",
  BM_EddyStoneId: "b19af0047f2a49728f3937d26c29cb9e",
  BM_Status: 0,
  BM_Major: 4339,
  BM_Minor: 9,
  BM_Lat: 22.256787,
  BM_Lng: 114.153054,
  BM_Easting: 839798,
  BM_Northing: 820333,
  BM_Gl: -1,
  BM_GridId: "1",
  BM_PositionIndicator: 0,
  BM_PowerIndicator: 0,
  BM_Hash: "267",
  BM_Remarks: null,
  BM_lamppostNo: "API Test 5",
  BM_Rsv1: null,
  BM_Instant: null,
  BM_TxPower: 4,
};

function getItem(label, key, children) {
  return {
    key,
    children,
    label,
  };
}

const columns = [
  {
    title: "",
    dataIndex: "selection",
  },
  // {
  //   title: "Id",
  //   dataIndex: "Id",
  // },
  {
    title: "CreateTime",
    dataIndex: "CreateTime",
  },
  {
    title: "UpdateTime",
    dataIndex: "UpdateTime",
  },
  // {
  //   title: "AnchorId",
  //   dataIndex: "AnchorId",
  // },
  // {
  //   title: "BeaconId",
  //   dataIndex: "BeaconId",
  // },
  {
    title: "Uuid",
    dataIndex: "Uuid",
  },
  // {
  //   title: "EddyStoneId",
  //   dataIndex: "EddyStoneId",
  // },
  // {
  //   title: "Status",
  //   dataIndex: "Status",
  // },
  {
    title: "Major",
    dataIndex: "Major",
  },
  {
    title: "Minor",
    dataIndex: "Minor",
  },
  {
    title: "Lat",
    dataIndex: "Lat",
  },
  {
    title: "Lng",
    dataIndex: "Lng",
  },
  {
    title: "Easting",
    dataIndex: "Easting",
  },
  {
    title: "Northing",
    dataIndex: "Northing",
  },
  {
    title: "Gl",
    dataIndex: "Gl",
  },
  // {
  //   title: "GridId",
  //   dataIndex: "GridId",
  // },
  // {
  //   title: "PositionIndicator",
  //   dataIndex: "PositionIndicator",
  // },
  // {
  //   title: "PowerIndicator",
  //   dataIndex: "PowerIndicator",
  // },
  // {
  //   title: "Hash",
  //   dataIndex: "Hash",
  // },
  // {
  //   title: "Remarks",
  //   dataIndex: "Remarks",
  // },
  {
    title: "lamppostNo",
    dataIndex: "LamppostNo",
  },
  // {
  //   title: "Rsv1",
  //   dataIndex: "Rsv1",
  // },
  // {
  //   title: "Instant",
  //   dataIndex: "Instant",
  // },
  {
    title: "TxPower",
    dataIndex: "TxPower",
  },
];

function App() {
  const [data, setData] = useState([]);
  const [timeNow, setTimeNow] = useState(getTimeNow());
  const [siderIndex, setSiderIndex] = useState(false);
  const [beaconList, setBeaconList] = useState([]);
  const [beacons, setBeacons] = useState([]);
  const [selectedBeacons, setSelectedBeacons] = useState([]);
  const [showConfirmDeleteBeacon, setShowConfirmDeleteBeacon] = useState(false);
  const [disableConfirmAddBeacon, setDisableConfirmAddBeacon] = useState(true);
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [lpCoor, setLpCoor] = useState();
  const [ibeaconConfig, setIbeaconConfig] = useState();

  var XLSX = require("xlsx");
  
  function getWindowHeight () {
    return (window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight);
  }

  const VirtualTable = (props) => {
    const { columns, scroll } = props;
    const [tableWidth, setTableWidth] = useState(0);
    const { token } = theme.useToken();
    const widthColumnCount = columns.filter(({ width }) => !width).length;
    const mergedColumns = columns.map((column) => {
      if (column.width) {
        return column;
      }
      return {
        ...column,
        width: Math.floor(tableWidth / widthColumnCount),
      };
    });
    const gridRef = useRef();
    const [connectObject] = useState(() => {
      const obj = {};
      Object.defineProperty(obj, 'scrollLeft', {
        get: () => {
          if (gridRef.current) {
            return gridRef.current?.state?.scrollLeft;
          }
          return null;
        },
        set: (scrollLeft) => {
          if (gridRef.current) {
            gridRef.current.scrollTo({
              scrollLeft,
            });
          }
        },
      });
      return obj;
    });
    const resetVirtualGrid = () => {
      gridRef.current?.resetAfterIndices({
        columnIndex: 0,
        shouldForceUpdate: true,
      });
    };
    useEffect(() => resetVirtualGrid, [tableWidth]);
    const renderVirtualList = (rawData, { scrollbarSize, ref, onScroll }) => {
      ref.current = connectObject;
      const totalHeight = rawData.length * 54;
      return (
        <Grid
          ref={gridRef}
          className="virtual-grid"
          columnCount={mergedColumns.length}
          columnWidth={(index) => {
            const { width } = mergedColumns[index];
            return totalHeight > scroll?.y && index === mergedColumns.length - 1
              ? width - scrollbarSize - 1
              : width;
          }}
          // height={scroll.y}
          height={scroll.y}
          rowCount={rawData.length}
          rowHeight={() => 80}
          width={tableWidth}
          onScroll={({ scrollLeft }) => {
            onScroll({
              scrollLeft,
            });
          }}
        >
          {({ columnIndex, rowIndex, style }) => (
            <div
              className={classNames('virtual-table-cell', {
                'virtual-table-cell-last': columnIndex === mergedColumns.length - 1,
              })}
              style={{
                ...style,
                boxSizing: 'border-box',
                padding: token.padding,
                borderBottom: `${token.lineWidth}px ${token.lineType} ${token.colorSplit}`,
                background: token.colorBgContainer,
              }}
            >
              {columnIndex == 0 ? <Checkbox onChange={() => handleItemClick(rowIndex)}></Checkbox> : rawData[rowIndex][mergedColumns[columnIndex].dataIndex]}
            </div>
          )}
        </Grid>
      );
    };
    return (
      <ResizeObserver
        onResize={({ width }) => {
          setTableWidth(width);
        }}
      >
        <Table
          {...props}
          height="100"
          className="virtual-table"
          columns={mergedColumns}
          pagination={false}
          components={{
            body: renderVirtualList,
          }}
        />
      </ResizeObserver>
    );
  };

  function handleItemClick(row) {
    console.log(row);
    var tempList = selectedBeacons;
    var found = false;
    for (var i = 0; i < tempList.length; i++){
      if (tempList[i] == row){
        tempList.splice(i, 1);
        found = true;
        break;
      }
    }
    if (!found){
      tempList.push(row);
    }
    console.log(tempList);
    setSelectedBeacons(selectedBeacons);
  }

  useEffect(() => {
    if (siderIndex) {
      getGetBeaconAPI();
    }
  }, [siderIndex]);

  const showMessage = (isSuccess) => {
    if (isSuccess) {
      messageApi.open({
        type: "success",
        content: "Successful!",
      });
    } else {
      messageApi.open({
        type: "error",
        content: "Failed!",
      });
    }
  };

  const showReInputMessage = () => {
    messageApi.open({
      type: "error",
      content: "Please enter sufficent beacon info!",
    });
  };

  const inputHandler = (e) => {
    const { name, value } = e.target;
    const newData = { ...data, [name]: value };
    setData(newData);
  };

  function getHash(index, date) {
    var d = new Date();
    var curr_year = d.getFullYear();
    var curr_month = ("0" + d.getMonth() + 1).slice(-2); //Months are zero based
    var curr_date = ("0" + d.getDate()).slice(-2);
    var curr_hour = ("0" + d.getHours()).slice(-2);
    var curr_minute = ("0" + d.getMinutes()).slice(-2);
    var curr_second = ("0" + d.getSeconds()).slice(-2);
    return curr_year + curr_month + curr_date + curr_hour + curr_minute + curr_second + index;
  }

  function getTimeNow() {
    var d = new Date();
    var curr_year = d.getFullYear();
    var curr_month = ("0" + (d.getMonth() + 1)).slice(-2); //Months are zero based
    var curr_date = ("0" + d.getDate()).slice(-2);
    var curr_hour = ("0" + d.getHours()).slice(-2);
    var curr_minute = ("0" + d.getMinutes()).slice(-2);
    var curr_second = ("0" + d.getSeconds()).slice(-2);
    return curr_year + "-" + curr_month + "-" + curr_date + "T" + curr_hour + ":" + curr_minute + ":" + curr_second;
  }

  const handleSiderClick = (e) => {
    console.log(e);
    setSiderIndex(e);
  };

  function generateAllBeaconsManual() {
    const param = [];
    for (var i = 1; i <= 4; i++) {
      for (var j = 1; j <= 2; j++) {
        if (!(i >= 3 && j == 1)) {
          var powerValue = 4;
          if (j == 1) {
            powerValue = -27;
          }
          const major = parseInt(data.BM_Major) + i * 10000;
          const minor = parseInt(data.BM_Minor) + j * 10000;
          const newData = {
            ...data,
            BM_BeaconId: "IB0000000000XX",
            BM_EddyStoneId: data.BM_Uuid,
            BM_Status: "0",
            BM_Major: major,
            BM_Minor: minor,
            BM_GridId: 1,
            BM_PositionIndicator: 0,
            BM_PowerIndicator: 0,
            BM_Hash: getHash(param.length, timeNow),
            BM_CreateTime: timeNow,
            BM_UpdateTime: timeNow,
            BM_TxPower: powerValue,
          };
          param.push(newData);
        }
      }
    }
    return param;
  }

  function generateAllBeaconsFile() {
    const param = [];
    const lpCoor_col_info = ["LP_NUM", "LP_NORTH", "LP_EAST", "LP_LAT", "LP_LONG", "LP_GL"];
    const ibeacon_col_info = [
      "LP_NUM",
      "UUID",
      "Position",
      "Grid ID",
      "Anchor ID",
      "Packet Format",
      "Power Mode",
      "High Power Major (for checking)",
      "High Power Minor (for checking)",
      "High Power Level (dBm)",
      "High Power RSSI (dBm)",
      "Low Power Major (for checking)",
      "Low Power Minor (for checking)",
      "Low Power Level (dBm)",
      "Low Power RSSI (dBm)",
      "Threshold (ms)",
      "Description",
      "Actual High Power Level (dBm)",
      "Actual Low Power Level (dBm)",
      "MAC Address",
    ];
    if (!checkEqual(lpCoor[0], lpCoor_col_info) && !checkEqual(ibeaconConfig[0], ibeacon_col_info)) {
      return false;
    }
    for (var l = 1; l < lpCoor.length; l++) {
      var ibeaconConfigIndex;
      ibeaconConfig.forEach(element => {
        if (element[0] === lpCoor[l][0]){
          ibeaconConfigIndex = ibeaconConfig.indexOf(element);
        }
      });
      for (var i = 1; i <= 4; i++) {
        for (var j = 1; j <= 2; j++) {
          if (!(i >= 3 && j == 1)) {
            var powerValue = 4;
            if (j == 1) {
              powerValue = -27;
            }
            const major = parseInt(ibeaconConfig[ibeaconConfigIndex][3]) + i * 10000;
            const minor = parseInt(ibeaconConfig[ibeaconConfigIndex][4]) + j * 10000;
            const newData = {
              ...data,
              // xlsx data
              BM_Uuid: ibeaconConfig[ibeaconConfigIndex][1],
              BM_Major: major,
              BM_Minor: minor,
              BM_Lat: lpCoor[l][3],
              BM_Lng: lpCoor[l][4],
              BM_Easting: Math.round(lpCoor[l][2]),
              BM_Northing: Math.round(lpCoor[l][1]),
              BM_Gl: lpCoor[l][5],
              BM_lamppostNo: lpCoor[l][0],

              BM_BeaconId: "IB0000000000XX",
              BM_EddyStoneId: ibeaconConfig[ibeaconConfigIndex][1],
              BM_Status: "0",
              BM_GridId: 1,
              BM_PositionIndicator: 0,
              BM_PowerIndicator: 0,
              BM_Hash: getHash(param.length, timeNow),
              BM_CreateTime: timeNow,
              BM_UpdateTime: timeNow,
              BM_TxPower: powerValue,
            };
            param.push(newData);
          }
        }
      }
    }
    return param;
  }

  const onSelectChange = (newSelectedRowKeys) => {
    console.log("selectedRowKeys changed: ", newSelectedRowKeys);
    setSelectedRowKeys(newSelectedRowKeys);
    setSelectedBeacons(newSelectedRowKeys);
  };

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
  };

  async function postSetBeaconAPI() {
    setTimeNow(getTimeNow());
    const endpoint = "api/beacon/setBeacon?key=key2";
    // generateAllBeacons();
    // console.log(generateAllBeacons());
    var param = generateAllBeaconsManual();
    if (lpCoor && ibeaconConfig){
      param = generateAllBeaconsFile();
      if (!param){
        showReInputMessage();
        return;
      }
    }
    console.log(param);
    const response = await API.post(endpoint, param);
    console.log("Response success: " + API.isSuccess(response));
    showMessage(API.isSuccess(response));
  }

  async function getGetBeaconAPI() {
    const endpoint = "api/beacon/b19af0047f2a49728f3937d26c29cb9e/2021-10-01?key=key2";
    const response = await API.get(endpoint);
    console.log("Response success: " + API.isSuccess(response));
    showMessage(API.isSuccess(response));
    setBeacons(response.data.data.content);
    const tempList = response.data.data.content;
    for (var i = 0; i < tempList.length; i++) {
      tempList[i].key = i;
      tempList[i].selection = i;
    }
    setBeaconList(tempList);
  }

  async function postDeleteBeaconAPI() {
    const endpoint = "api/beacon/deleteBeacon?key=key2";
    const param = [];
    selectedBeacons.forEach((element) => {
      const entity = {};
      entity.BM_Id = beacons[element].Id;
      entity.BM_CreateTime = beacons[element].CreateTime;
      entity.BM_UpdateTime = beacons[element].UpdateTime;
      entity.BM_AnchorId = beacons[element].AnchorId;
      entity.BM_BeaconId = beacons[element].BeaconId;
      entity.BM_Uuid = beacons[element].Uuid;
      entity.BM_EddyStoneId = beacons[element].EddyStoneId;
      entity.BM_Status = beacons[element].Status;
      entity.BM_Major = beacons[element].Major;
      entity.BM_Minor = beacons[element].Minor;
      entity.BM_Lat = beacons[element].Lat;
      entity.BM_Lng = beacons[element].Lng;
      entity.BM_Easting = beacons[element].Easting;
      entity.BM_Northing = beacons[element].Northing;
      entity.BM_Gl = beacons[element].Gl;
      entity.BM_GridId = beacons[element].GridId;
      entity.BM_PositionIndicator = beacons[element].PositionIndicator;
      entity.BM_PowerIndicator = beacons[element].PowerIndicator;
      entity.BM_Hash = beacons[element].Hash;
      entity.BM_Remarks = beacons[element].Remarks;
      entity.BM_lamppostNo = beacons[element].LamppostNo;
      entity.BM_Rsv1 = beacons[element].Rsv1;
      entity.BM_Instant = beacons[element].Instant;
      entity.BM_TxPower = beacons[element].TxPower;
      param.push(entity);
    });
    console.log(param);
    const response = await API.post(endpoint, param);
    console.log("Response success: " + API.isSuccess(response));
    setShowConfirmDeleteBeacon(false);
    if (API.isSuccess(response)) {
      setSelectedRowKeys([]);
      getGetBeaconAPI();
      showMessage(true);
    } else {
      showMessage(false);
    }
  }

  function checkEqual(array1, array2) {
    return (
      array1.length === array2.length &&
      array1.every(function (value, index) {
        return value === array2[index];
      })
    );
  }

  function checkHaveInput() {
    // try {
    if ((data.BM_Uuid && data.BM_Major && data.BM_Minor && data.BM_Lat && data.BM_Lng && data.BM_Easting && data.BM_Northing && data.BM_Gl && data.BM_lamppostNo) || (lpCoor && ibeaconConfig)) {
      setDisableConfirmAddBeacon(false);
    } else {
      setDisableConfirmAddBeacon(true);
      showReInputMessage();
      setDisableConfirmAddBeacon(false);
    }
    // } catch (error) {
    //   showReInputMessage()
    // }
  }

  const handleUpload = (e) => {
    e.preventDefault();

    var files = e.target.files,
      f = files[0];
    var reader = new FileReader();
    reader.onload = function (e) {
      var data = e.target.result;
      let readedData = XLSX.read(data, { type: "binary" });
      const wsname1 = readedData.SheetNames[0];
      const ws1 = readedData.Sheets[wsname1];
      /* Convert array to json*/
      const dataParse1 = XLSX.utils.sheet_to_json(ws1, { header: 1 });

      const wsname2 = readedData.SheetNames[1];
      const ws2 = readedData.Sheets[wsname2];
      /* Convert array to json*/
      const dataParse2 = XLSX.utils.sheet_to_json(ws2, { header: 1 });

      setLpCoor(dataParse1);
      setIbeaconConfig(dataParse2);
    };
    reader.readAsBinaryString(f);
  };

  return (
    <>
      {contextHolder}
      <Layout style={{ minHeight: "100vh" }}>
        <Header>
          <Title level={4} color={"#fff"}>
            landslp Beacon Editor
          </Title>
        </Header>
        <Layout>
          <Sider>
            <Menu theme="dark" mode="inline">
              <MenuItem key={1} onClick={() => handleSiderClick(false)} defaultChecked={true}>
                Add Beacon
              </MenuItem>
              <MenuItem key={2} onClick={() => handleSiderClick(true)}>
                View Beacon
              </MenuItem>
            </Menu>
          </Sider>
          <Content hidden={siderIndex}>
            <div className="context-div">
              <div className="input-div">
                <div>
                  <Form
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 14,
                    }}
                    layout="horizontal"
                    className="form-div"
                  >
                    {/* <Form.Item label="BM_Id">
                  <Input name="BM_Id" defaultValue="**Auto Generated**" disabled={true} />
                </Form.Item>
                <Form.Item label="BM_CreateTime">
                  <Input name="BM_CreateTime" onChange={inputHandler} value={timeNow} disabled={true} />
                </Form.Item>
                <Form.Item label="BM_UpdateTime">
                  <Input name="BM_UpdateTime" onChange={inputHandler} value={timeNow} disabled={true} />
                </Form.Item>
                <Form.Item label="BM_AnchorId">
                  <Input name="BM_AnchorId" onChange={inputHandler} disabled={true} />
                </Form.Item>
                <Form.Item label="BM_BeaconId">
                  <Input name="BM_BeaconId" defaultValue="IB0000000000XX"  onChange={inputHandler} disabled={true} />
                </Form.Item> */}
                    <Form.Item label="UUID">
                      <Input name="BM_Uuid" onChange={inputHandler} />
                    </Form.Item>
                    {/* <Form.Item label="BM_EddyStoneId">
                  <Input name="BM_EddyStoneId" onChange={inputHandler} disabled={true} />
                </Form.Item>
                <Form.Item label="BM_Status">
                  <Input name="BM_Status" onChange={inputHandler} type="number" disabled={true} />
                </Form.Item> */}
                    <Form.Item label="Grid ID">
                      <Input name="BM_Major" onChange={inputHandler} type="number" />
                    </Form.Item>
                    <Form.Item label="Anchor ID">
                      <Input name="BM_Minor" onChange={inputHandler} type="number" />
                    </Form.Item>
                    <Form.Item label="Lat">
                      <Input name="BM_Lat" onChange={inputHandler} type="number" />
                    </Form.Item>
                    <Form.Item label="Long">
                      <Input name="BM_Lng" onChange={inputHandler} type="number" />
                    </Form.Item>
                  </Form>
                </div>

                <div>
                  <Form
                    labelCol={{
                      span: 6,
                    }}
                    wrapperCol={{
                      span: 14,
                    }}
                    layout="horizontal"
                    className="form-div"
                  >
                    <Form.Item label="Easting">
                      <Input name="BM_Easting" onChange={inputHandler} type="number" />
                    </Form.Item>
                    <Form.Item label="Northing">
                      <Input name="BM_Northing" onChange={inputHandler} type="number" />
                    </Form.Item>
                    <Form.Item label="Gl">
                      <Input name="BM_Gl" onChange={inputHandler} type="number" />
                    </Form.Item>
                    {/* <Form.Item label="BM_GridId">
                  <Input name="BM_GridId" onChange={inputHandler} disabled={true} />
                </Form.Item>
                <Form.Item label="BM_PositionIndicator">
                  <Input name="BM_PositionIndicator" onChange={inputHandler} type="number" disabled={true} />
                </Form.Item>
                <Form.Item label="BM_PowerIndicator">
                  <Input name="BM_PowerIndicator" onChange={inputHandler} type="number" disabled={true} />
                </Form.Item>
                <Form.Item label="BM_Hash">
                  <Input name="BM_Hash" onChange={inputHandler} defaultValue="**Auto Generated**" disabled={true} />
                </Form.Item>
                <Form.Item label="BM_Remarks">
                  <Input name="BM_Remarks" onChange={inputHandler} disabled={true} />
                </Form.Item> */}
                    <Form.Item label="Lamppost No.">
                      <Input name="BM_lamppostNo" onChange={inputHandler} />
                    </Form.Item>
                    {/* <Form.Item label="BM_Rsv1">
                  <Input name="BM_Rsv1" onChange={inputHandler} disabled={true} />
                </Form.Item>
                <Form.Item label="BM_Instant">
                  <Input name="BM_Instant" onChange={inputHandler} disabled={true} />
                </Form.Item>
                <Form.Item label="BM_TxPower">
                  <Input name="BM_TxPower" onChange={inputHandler} disabled={true} />
                </Form.Item> */}
                  </Form>
                  <form className="file-input">
                    <input type="file" onChange={handleUpload}></input>
                  </form>
                  <div className="return-bin-button-div">
                    <Popconfirm
                      placement="bottom"
                      icon="⚠️"
                      title="Confirm add beacons"
                      description="Are you sure to add all 6 beacons with this configuration?"
                      onConfirm={() => postSetBeaconAPI()}
                      okText="confirm"
                      cancelText="cancel"
                      disabled={disableConfirmAddBeacon}
                      // onOpenChange={}
                    >
                      <Button type="primary" size="large" onClick={() => checkHaveInput()}>
                        Add Beacons
                      </Button>
                    </Popconfirm>
                  </div>
                </div>
              </div>
            </div>
          </Content>
          <Content hidden={!siderIndex}>
            <div >
              <VirtualTable
                columns={columns}
                dataSource={beaconList}
                className="table"
                scroll={{
                  y: (getWindowHeight() - 64 - 90 - 67 - 120),
                  x: '100vw',
                }}
              />
              {/* <Table
                rowSelection={{
                  type: selectionType,
                  ...rowSelection,
                }}
                pagination={false}
                scroll={{
                  y: "70vh",
                }}
                columns={columns}
                dataSource={beaconList}
                className="table"
                bordered="false"
              /> */}
              <div>
                <Button type="primary" size="large" className="return-bin-button-div" onClick={() => getGetBeaconAPI()}>
                  Refresh Beacons
                </Button>
                <Popconfirm
                  placement="bottom"
                  icon="⚠️"
                  title="Confirm delete beacons"
                  description="Are you sure to delete the selected beacons?"
                  onConfirm={() => postDeleteBeaconAPI()}
                  okText="confirm"
                  cancelText="cancel"
                  disabled={showConfirmDeleteBeacon}
                  // onOpenChange={}
                >
                  <Button type="primary" size="large" className="return-bin-button-div" onClick={() => setShowConfirmDeleteBeacon(true)}>
                    Delete Selected
                  </Button>
                </Popconfirm>
              </div>
            </div>
          </Content>
        </Layout>
        <Footer>LSCM all rights reserved. {timeNow}</Footer>
      </Layout>
    </>
  );
}

export default App;
